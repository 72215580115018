<template>
	<div style="    margin: 30px 20px;">
		<div style="width: 100%;min-height:1000px;background: #ffffff">
			<div class="content" v-html="hello">
			</div>
		</div>
	</div>
</template>

<script>
	import {
		asa
	} from '../api/authority'
	import 'highlight.js/styles/monokai-sublime.css';
	export default {
		name: "asa",
		data() {
			return {
				hello: ''
			}
		},
		mounted() {
			this.getlmg();

		},
		methods: {
			getlmg() {
				asa().then((response) => {
					console.log(response)
					this.hello = response.data
				})
			}

		}
	}
</script>

<style>
</style>
